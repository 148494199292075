import React from "react";
import { ThemeProvider } from "react-bootstrap";
import ProgressBar from "../components/effects/ProgressBar";
import ScrollToTop from "../components/effects/ScrollToTop";

export default function BaseLayout({ children }) {
  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      minBreakpoint="xxs"
    >
      <ProgressBar />
      <ScrollToTop />
      <>{children}</>
    </ThemeProvider>
  );
}
