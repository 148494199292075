import React from "react";
import { Helmet } from "react-helmet";

const MetaHome = () => {
  return (
      <Helmet>
        <title>
          Frantz Rouzé - Webdesigner et développeur web passionné
        </title>
        <meta name="description" content="Découvrez mon CV en ligne de webdesigner et développeur web polyvalent, capable de concevoir des sites web dynamiques et fonctionnels tout en ayant un sens esthétique aiguisé." />
        <link rel="canonical" href="https://frantzrouze.com" />
        <meta
          property="og:title"
          content="Frantz Rouzé - Webdesigner et développeur web passionné"
        />
        <meta property="og:description" content="Découvrez mon CV en ligne de webdesigner et développeur web polyvalent, capable de concevoir des sites web dynamiques et fonctionnels tout en ayant un sens esthétique aiguisé." />
        <meta property="og:type" content="siteweb" />
        <meta property="og:site_name" content="Frantz Rouzé (CV's)" />
        <meta property="og:url" content="frantzrouze.com" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "@id":"https://frantzrouze.com",
            "url":"https://frantzrouze.com",
            "name": "Frantz Rouzé - Webdesigner et développeur web passionné",
            "description": "Découvrez mon CV en ligne de webdesigner et développeur web polyvalent, capable de concevoir des sites web dynamiques et fonctionnels tout en ayant un sens esthétique aiguisé.",
            "author": {
              "@type": "Person",
              "name": "Frantz Rouzé"
            },
            "datePublished": "2023-05-02",
            "inLanguage":"fr-FR"
          }
        `}</script>
      </Helmet>
  );
};

export default MetaHome;
