import React from "react";
import { Nav } from "react-bootstrap";
import NavigationMobile from "./NavMobile";
import NavigationDesktop from "./NavDesktop";

const Navigation = () => {
  return (
    <Nav className="justify-content-center">
      <NavigationMobile />
      <NavigationDesktop />
    </Nav>
  );
};

export default Navigation;
