import React from "react";
import NavContent from "./NavContent";

const NavDesktop = () => {
  return (
    <div className="nav-desktop">
      <NavContent />
    </div>
  );
};

export default NavDesktop;
