import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavContent = () => {
  return (
    <div className="nav-content">
      <Nav.Item>
        <NavLink
          to="/"
          className={(nav) =>
            nav.isActive ? "nav-active nav-link" : "nav-link"
          }
          data-hover="Home"
          aria-label="Home"
        >
          <i className="fa-solid fa-house"></i>
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink
          to="/webdesigner-et-developpeur-web"
          className={(nav) =>
            nav.isActive ? "nav-active nav-link" : "nav-link"
          }
          data-hover="A propos"
          aria-label="A propos"
        >
          A propos
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink
          to="/mon-parcours-de-webdesigner-et-developpeur-web"
          className={(nav) =>
            nav.isActive ? "nav-active nav-link" : "nav-link"
          }
          data-hover="Parcours"
          aria-label="Parcours"
        >
          Parcours
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink
          to="/mes-competences-de-webdesigner-et-developpeur-web"
          className={(nav) =>
            nav.isActive ? "nav-active nav-link" : "nav-link"
          }
          data-hover="Compétences"
          aria-label="Compétences"
        >
          Compétences
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink
          to="/mes-projets-de-webdesigner-et-developpeur-web"
          className={(nav) =>
            nav.isActive ? "nav-active nav-link" : "nav-link"
          }
          data-hover="Projets"
          aria-label="Projets"
        >
          Projets
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink
          to="/Besoin-d-un-webdesigner-et-developpeur-web"
          className={(nav) =>
            nav.isActive ? "nav-active nav-link" : "nav-link"
          }
          data-hover="Contact"
          aria-label="Contact"
        >
          <i className="fa-solid fa-address-book"></i>
        </NavLink>
      </Nav.Item>
    </div>
  );
};

export default NavContent;
