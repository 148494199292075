import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container className="d-flex flex-wrap px-sm-5">
      <div className="footer text-center text-md-end me-md-3">
        Copyright © {year} | WebSite by <NavLink to="/">Frantz Rouzé</NavLink>
        <br />
        <NavLink to="/mentions-legales">Mentions Légales</NavLink> |{" "}
        <NavLink to="/politique-de-confidentialite">
          Politique de confidentialité
        </NavLink>
      </div>
      <div className="social-footer text-center text-sm-start ms-md-3">
        <a
          href="https://fr.linkedin.com/in/frantz-rouz%C3%A9"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Linkedin"
        >
          <i className="fa-brands fa-linkedin"></i>
        </a>
        <a
          href="https://www.github.com/FR-DESIGN"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub"
        >
          <i className="fa-brands fa-github"></i>
        </a>
        <a
          href="https://www.facebook.com/frantz.rouze.pro"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <i className="fa-brands fa-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/frantz_fr_design"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <i className="fa-brands fa-instagram"></i>
        </a>
      </div>
    </Container>
  );
};

export default Footer;
