import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";

const router = createBrowserRouter([
  { path: "/", Component: Home },

  {
    path: "webdesigner-et-developpeur-web",
    async lazy() {
      let { About } = await import("./pages/About");
      return { Component: About };
    },
  },
  {
    path: "mon-parcours-de-webdesigner-et-developpeur-web",
    async lazy() {
      let { Career } = await import("./pages/Career");
      return { Component: Career };
    },
  },
  {
    path: "mes-competences-de-webdesigner-et-developpeur-web",
    async lazy() {
      let { Skills } = await import("./pages/Skills");
      return { Component: Skills };
    },
  },
  {
    path: "mes-projets-de-webdesigner-et-developpeur-web",
    async lazy() {
      let { Projects } = await import("./pages/Projects");
      return { Component: Projects };
    },
  },
  {
    path: "Besoin-d-un-webdesigner-et-developpeur-web",
    async lazy() {
      let { Contact } = await import("./pages/Contact");
      return { Component: Contact };
    },
  },
  {
    path: "politique-de-confidentialite",
    async lazy() {
      let { Confidentiality } = await import(
        "./pages/administratif/Confidentiality"
      );
      return { Component: Confidentiality };
    },
  },
  {
    path: "mentions-legales",
    async lazy() {
      let { LegalNotices } = await import("./pages/administratif/LegalNotices");
      return { Component: LegalNotices };
    },
  },

  {
    path: "*",
    async lazy() {
      let { Page404 } = await import("./pages/Page404");
      return { Component: Page404 };
    },
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
