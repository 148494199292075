import React from "react";
import NavContent from "./NavContent";

const NavMobile = () => {
  return (
    <div className="nav-mobile">
      <input type="checkbox" id="active" />
      <label htmlFor="active" className="menu-btn">
        <i className="fas fa-bars"></i>
      </label>
      <NavContent />
    </div>
  );
};

export default NavMobile;
