import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import Buttons from "../components/buttons/ButtonsNav";
import Footer from "../components/navigation/Footer";
import Navigation from "../components/navigation/Navigation";
import MetaHome from "../meta/MetaHome";
import { motion } from "framer-motion";
import DynamicTitle from "../components/effects/DynamicTitle";
// import Wallpaper from "../components/effects/Wallpaper";
import BaseLayout from "../layout/BaseLayout";

export default function Home() {
  const beforeTitre = "{ ";
  const afterTitre = " };";
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <BaseLayout>
      <Container fluid>
        {/* <Wallpaper /> */}
        <MetaHome />
        <Navigation />
        <Container className="d-flex home justify-content-center">
          <div className="screen">
            <div className="screen-menu">
              <ul>
                <li className="red"></li>
                <li className="orange"></li>
                <li className="green"></li>
              </ul>
            </div>
            <div className="d-flex flex-column align-self-center screen-content">
              <motion.h1
                initial={{ opacity: 0, y: -150, x: 150 }}
                animate={{ opacity: 0.5, y: 0, x: 0 }}
                transition={{ duration: 1.5 }}
              >
                <strong>
                  <NavLink to={"/webdesigner-et-developpeur-web"}>
                    Frantz Rouzé
                  </NavLink>
                </strong>{" "}
                <span className="text-regular">(</span>
                <span className="text-italic">CV's</span>
                <span className="text-regular">)</span>
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0, scale: 0.25 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.5 }}
              >
                <span>{beforeTitre}</span>Le <strong>designer</strong> doublé
                d'un <strong>développeur</strong>
                <span>{afterTitre}</span>
              </motion.h2>
              <p>
                <DynamicTitle />
              </p>
            </div>
          </div>
        </Container>
        <Buttons right={"/webdesigner-et-developpeur-web"} />
        <footer className="position-absolute bottom-0 d-none d-md-block">
          <Footer />
        </footer>
      </Container>
    </BaseLayout>
  );
}
