import React, { useEffect } from "react";
import { motion } from "framer-motion";

const DynamicTitle = () => {
  // const sousTitre = "// Spécialisé en";
  useEffect(() => {
    const target = document.getElementById("text-target");
    let array = [
      "\u00a0" + "Spécialisé" + "\u00a0" + "en" + "\u00a0" + "'Webdesign'",
      "\u00a0" + "Spécialisé" + "\u00a0" + "en" + "\u00a0" + "'Front-end'",
      "\u00a0" +
        "Spécialisé" +
        "\u00a0" +
        "en" +
        "\u00a0" +
        "'UX" +
        "\u00a0" +
        "Design'",
      "\u00a0" +
        "Spécialisé" +
        "\u00a0" +
        "en" +
        "\u00a0" +
        "'UI" +
        "\u00a0" +
        "Design'",
    ];
    let wordIndex = 0;
    let letterIndex = 0;

    const createLetter = () => {
      const letter = document.createElement("span");
      target.appendChild(letter);

      letter.classList.add("letter");
      letter.style.opacity = "0";
      letter.style.animation = "anim 15s ease forwards";
      letter.textContent = array[wordIndex][letterIndex];

      setTimeout(() => {
        letter.remove();
      }, 6000);
    };

    const loop = () => {
      setTimeout(() => {
        if (wordIndex >= array.length) {
          wordIndex = 0;
          letterIndex = 0;
          loop();
        } else if (letterIndex < array[wordIndex].length) {
          createLetter();
          letterIndex++;
          loop();
        } else {
          letterIndex = 0;
          wordIndex++;
          setTimeout(loop, 6000);
        }
      }, 50);
    };
    loop();
  }, []);

  return (
    <motion.span
      className="dynamic-title"
      initial={{ opacity: 0, y: 150, x: 150 }}
      animate={{ opacity: 1, y: 0, x: 0 }}
      transition={{ duration: 1.5 }}
    >
      {/* <span>//</span> */}
      <span id="text-target">//</span>
    </motion.span>
  );
};

export default DynamicTitle;
